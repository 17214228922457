import type { Entities } from '@inwink/entities/entities';
import type { States } from "@@services/services";
import { getCMSPreview } from '@@services/previewservice';
import type { IDoc } from '@inwink/inwinkdb/common/types';

const INITIAL_STATE = {
    detail: null,
    tinyUrl: null,
    eventid: null
} as States.IEventState;

export function eventReducer(state: States.IEventState = INITIAL_STATE, action = { type: '', payload: null }) {
    switch (action.type) {
        case "EVENT_SETID": {
            return {
                ...state,
                eventid: action.payload.eventid,
                tenantid: action.payload.tenantid,
                customerid: action.payload.customerid,
            };
        }
        case "EVENT_SETTEMPLATES": {
            const res = {};
            res[action.payload.application] = action.payload.templates;
            return { ...state, apptemplates: { ...res } };
        }
        case "EVENT_REFRESH": {
            return { ...state };
        }
        case "EVENT_SET": {
            if (InWinkPreview) {
                if (typeof window !== "undefined") {
                    getCMSPreview().then((previewservice) => {
                        previewservice.setEvent(action.payload);
                    });
                }
            }

            return {
                ...state,
                requestManagers: action.payload.requestManagers,
                eventReady: (action.payload.eventReady) || false,
                detail: action.payload.event,
                data: action.payload.data,
                tenantid: action.payload.event.tenantId,
                customerid: action.payload.event.customerId,
                eventid: action.payload.event ? action.payload.event.id : null,
                tinyUrl: action.payload.event ? action.payload.event.tinyUrl : null,
                apptemplates: null
            };
        }

        case "EVENT_ISREADY": {
            return { ...state, eventReady: true };
        }

        case "EVENT_SETDATA": {
            return {
                ...state,
                data: action.payload,
                stamp: new Date()
            };
        }

        case "EVENT_DATACHANGED": {
            if (action.payload && action.payload.eventData) {
                return {
                    ...state,
                    data: action.payload.eventData,
                    currentUser: { ...state, stamp: new Date() }
                };
            }

            return { ...state, currentUser: { ...state, stamp: new Date() } };
        }

        case "EVENT_SETDETAIL": {
            return { ...state, detail: action.payload };
        }

        case "EVENT_ADDORUPDATETEMPLATE": {
            const newTemplate: Entities.IContentTemplateBase<any> = { ...action.payload };
            const actualTemplate = state.data.templates.data.find((t) => t.application === newTemplate.application
                && t.contentType === newTemplate.contentType
                && t.tinyUrl === newTemplate.tinyUrl);

            if (actualTemplate) {
                const defaultMeta = {
                    created: 0,
                    revision: 0,
                    version: 0,
                    updated: null
                };
                const newTemplateStorable: IDoc<Entities.IContentTemplateBase<any>> =
                    { 
                        ...newTemplate,
                        $loki: actualTemplate.$loki,
                        meta: actualTemplate.meta ?? defaultMeta
                    };
                state.data.templates.update(newTemplateStorable);
            } else {
                state.data.templates.insert(newTemplate);
            }

            state.data.save();
            return state;
        }

        default:
            return state;
    }
}

export const actions = {
    eventDataChanged(eventData?: States.IEventDataStore) {
        return (dispatch) => {
            dispatch({ type: 'EVENT_DATACHANGED', payload: { eventData } });
        };
    },

    setApplicationTemplates(application: string, templates) {
        return (dispatch) => {
            dispatch({ type: 'EVENT_SETTEMPLATES', payload: { application, templates } });
        };
    },

    setEventId(eventid: string, tenantId: string, customerId: string) {
        return (dispatch) => {
            dispatch({
                type: 'EVENT_SETID',
                payload: {
                    eventid,
                    tenantid: tenantId,
                    customerid: customerId
                }
            });
        };
    },

    clearCurrentEvent() {
        return (dispatch) => {
            dispatch({ type: 'EVENT_SET', payload: { event: null, data: null } });
        };
    },
};
